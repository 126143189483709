import * as React from "react"
import PropTypes from "prop-types"
import  MainMenu  from "./mainMenu.js"

const Header = ({ siteTitle }) => (

  
  
  <header>
      
      <MainMenu></MainMenu>
  </header> 
  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
